<template>
  <section class="q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="home" class="q-mb-xl"></Mural>
    <q-form @submit="onSubmit" @reset="onReset" class="">
      <div class="container-form">
        <div class="container-input">
          <label class="label-input">Título</label>
          <q-input
            v-model="surveyTemplate.title"
            type="text"
            dense
            outlined
            placeholder="digite aqui"
            color="default-pink"
            bg-color="grey-3"
            :rules="[(val) => !!val || 'Preencha o campo título']"
          />
        </div>
        <div class="container-input">
          <label class="label-input">Descrição</label>
          <q-input
            v-model="surveyTemplate.description"
            type="text"
            dense
            outlined
            placeholder="digite aqui"
            color="default-pink"
            bg-color="grey-3"
            :rules="[(val) => !!val || 'Preencha o campo descrição']"
          />
        </div>
        <div class="container-input">
          <label class="label-input">Sua pergunta</label>
          <q-input
            v-model="surveyTemplate.question"
            type="text"
            dense
            outlined
            color="default-pink"
            bg-color="grey-3"
            placeholder="digite aqui"
            :rules="[(val) => !!val || 'Preencha o campo sua pergunta']"
          />
        </div>
        <div class="q-mb-md">
          <div class="row items-center">
            <label for="semgments" class="label q-mr-sm">Segmentos</label>
            <q-img
              src="@/assets/icons/info_cicle.svg"
              width="16px"
              height="16px"
            />
            <q-tooltip anchor="top left" self="top left">
              Utilize os segmentos para poder filtrar os grupos
            </q-tooltip>
          </div>
          <q-select
            dense
            outlined
            color="default-pink"
            bg-color="grey-3"
            class="col-11"
            id="semgments"
            multiple
            use-chips
            use-input
            emit-value
            map-options
            @filter="filterFnBranches"
            v-model="surveyTemplate.branches"
            :options="listBranches"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <div class="container-input">
          <label class="label-input">Grupos</label>
          <div class="container-groups">
            <q-select
              class="input-groups"
              v-model="surveyTemplate.groups"
              :options="optionsGroups"
              @filter="filterFnGroups"
              multiple
              emit-value
              use-chips
              map-options
              dense
              use-input
              outlined
              color="default-pink"
              bg-color="grey-3"
              :rules="[(val) => !val.length == 0 || 'Preencha o campo grupos']"
              ><template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template></q-select
            >
            <q-checkbox
              left-label
              class="col-2"
              v-model="allGroups"
              label="Selecionar todos"
              color="default-pink"
              @update:model-value="selectedFilteredGroups"
            />
          </div>
        </div>
        <div>
          <div
            class="container-input"
            v-for="(option, index) in surveyTemplate.options"
            :key="index"
          >
            <div class="container-label">
              <label class="label-input">Opção {{ index + 1 }}</label>
              <q-icon
                v-if="![0, 1].includes(index)"
                color="primary"
                name="close"
                class="cursor-pointer self-right"
                @click="deleteOption(index)"
                flat
                size="15px"
              />
            </div>
            <q-input
              v-model="surveyTemplate.options[index].option"
              type="text"
              dense
              outlined
              placeholder="digite aqui"
              color="default-pink"
              bg-color="grey-3"
              :rules="[(val) => !!val || `Preencha o campo opção ${index + 1}`]"
            >
            </q-input>
          </div>
          <q-btn
            icon="add"
            label="Adicionar opção"
            color="default-pink"
            outline
            class="text-weight-bold more-options q-mb-md"
            no-caps
            :disable="surveyTemplate.options.length === 4 ? true : false"
            @click="moreOptions"
          />
        </div>
        <div class="container-input">
          <label class="label-input">Duração da enquete</label>
          <q-input
            dense
            outlined
            placeholder="digite aqui"
            color="default-pink"
            bg-color="grey-3"
            type="text"
            v-model="surveyTemplate.duration"
            :rules="[(val) => !!val || `Preencha o campo duração da enquete`]"
          >
            <template v-slot:append>
              <q-icon name="event" class="cursor-pointer">
                <q-popup-proxy
                  cover
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date
                    v-model="surveyTemplate.duration"
                    mask="DD/MM/YYYY HH:mm:ss"
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
              <q-icon name="access_time" class="cursor-pointer">
                <q-popup-proxy
                  cover
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-time
                    v-model="surveyTemplate.duration"
                    mask="DD/MM/YYYY HH:mm:ss"
                    format24h
                  >
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-time>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
        <div class="container-inputs">
          <div class="item">
            <label class="label-input">Categoria</label>
            <q-select
              v-model="surveyTemplate.category"
              :options="listCategories"
              emit-value
              map-options
              dense
              placeholder="Selecione a duração"
              outlined
              color="default-pink"
              bg-color="grey-3"
              :rules="[(val) => !!val || 'Preencha o campo categoria']"
            />
          </div>
          <div class="item">
            <label class="label-input">Tags</label>
            <q-select
              v-model="surveyTemplate.tags"
              :options="filterListItens"
              dense
              emit-value
              map-options
              outlined
              color="default-pink"
              bg-color="grey-3"
              :rules="[(val) => !!val || 'Preencha o campo tags']"
            />
          </div>
        </div>
      </div>
      <div class="row justify-between q-mt-md">
        <q-btn
          label="Cancelar"
          color="default-pink"
          outline
          @click="router.go(-1)"
          class="text-weight-bold"
          no-caps
        />
        <q-btn
          label="Criar enquete"
          type="submit"
          color="default-pink"
          class="text-weight-bold"
          no-caps
          size="md"
        />
      </div>
    </q-form>
  </section>
</template>

<script>
/* Vue */
import { ref, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";

/* Router */
import { useRouter } from "vue-router";

/* Components */
import Mural from "@/components/Mural.vue";

/* Services */
import PostDataServices from "@/services/PostDataServices";
import GroupServices from "@/services/GroupServices";

/* Quasar */
import { date, useQuasar } from "quasar";

export default {
  name: "CreateSurvey",
  components: { Mural },
  setup() {
    /* Services */
    const postDataServices = new PostDataServices();
    const groupServices = new GroupServices();
    const store = new useStore();
    const router = new useRouter();
    const $q = useQuasar();

    /* Constants */
    const breadcrumbs = ref(["Criar uma enquete"]);
    const surveyTemplate = reactive({
      title: "",
      description: "",
      question: "",
      options: [{ option: "" }, { option: "" }],
      duration: "",
      category: null,
      tags: null,
      groups: [],
      branches: [],
      duration: date.formatDate(new Date(), "DD/MM/YYYY H:mm:ss"),
    });
    const filterListItens = ref([
      { label: "Tudo", value: 1 },
      { label: "Conquistas", value: 2 },
      { label: "Conhecimento", value: 3 },
      { label: "Dúvidas", value: 4 },
    ]);

    const listCategories = ref([]);
    const allGroups = ref(false);
    const listBranches = ref([]);
    const optionListBranches = ref([]);
    const listGroups = ref([]);
    const optionsGroups = ref([]);

    /* Functions */
    async function onSubmit() {
      const formData = new FormData();
      const date = surveyTemplate.duration.split(" ");
      const dateDays = date[0].split("/").reverse().join("-");

      formData.append("Title", surveyTemplate.title);
      formData.append("Description", surveyTemplate.description);
      formData.append("Your_question", surveyTemplate.question);
      formData.append("Category_id", surveyTemplate.category);
      formData.append("Survey_duration", `${dateDays} ${date[1]}`);

      surveyTemplate.options.forEach((option) => {
        formData.append("OptionsDescription", option.option);
      });
      surveyTemplate.groups.forEach((group) => {
        formData.append("Groups_id", group);
      });
      formData.append("Tag_id", surveyTemplate.tags);

      await postDataServices
        .createSurvey(formData)
        .then((response) => {
          if (response.data) {
            $q.notify({
              color: "green",
              message: `A enquete <strong>${surveyTemplate.title}</strong> foi criada com sucesso!`,
              html: true,
            });
            setTimeout(() => {
              router.go(-1);
            }, 2000);
          }
        })
        .catch((error) => {
          $q.notify({
            color: "green",
            message: `Ocorreu um erro ao tentar criar a enquete <strong>${surveyTemplate.title}</strong>, por favor tente novamente!`,
            html: true,
            caption: error,
          });
        });
    }

    function moreOptions() {
      surveyTemplate.options.push({ option: "" });
    }

    function deleteOption(index) {
      surveyTemplate.options.splice(index, 1);
    }

    async function listAllCategories() {
      await postDataServices
        .getAllCategories(store.state.userdata.company_id)
        .then((response) => {
          response.data.map((item) => {
            listCategories.value.push({
              label: item.description,
              value: item.id,
              image: item.image_path,
            });
          });
        });
    }

    async function _getAllGroups() {
      groupServices
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
              branch_id: item.branch_id,
            });
          });

          optionsGroups.value = listGroups.value;
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          if (surveyTemplate.branches.length > 0) {
            optionsGroups.value = listGroups.value.filter((group) => {
              return surveyTemplate.branches.includes(group.branch_id);
            });
          }
          optionsGroups.value = listGroups.value;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsGroups.value = optionsGroups.value.filter((v) => {
          if (surveyTemplate.branches.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              surveyTemplate.branches.includes(v.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }

    async function getBranches() {
      await groupServices
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function selectedFilteredGroups() {
      if (allGroups.value) {
        optionsGroups.value.filter((group) => {
          if (!surveyTemplate.groups.includes(group.value)) {
            surveyTemplate.groups.push(group.value);
          }
        });
      } else {
        surveyTemplate.groups = null;
        surveyTemplate.groups = [];
      }
    }

    /* Lifecycles */
    onMounted(() => {
      listAllCategories();
      getBranches();
      _getAllGroups();
    });

    watch(
      () => surveyTemplate.branches,
      (newBranch, oldBranch) => {
        if (newBranch.length == 0) {
          surveyTemplate.groups = null;
          surveyTemplate.groups = [];
          allGroups.value = false;
        }

        if (newBranch.length != oldBranch.length) {
          optionsGroups.value = listGroups.value;
        }

        if (newBranch.length != 0) {
          optionsGroups.value = listGroups.value.filter((group) => {
            return newBranch.includes(group.branch_id);
          });

          let groupValues = optionsGroups.value.map((group) => {
            return group.value;
          });

          surveyTemplate.groups = groupValues.filter((value) => {
            if (surveyTemplate.groups.includes(value)) {
              return value;
            }
          });
        }
      }
    );

    watch(
      () => surveyTemplate.groups,
      (newGroup, oldGroup) => {
        if (newGroup.length == 0) {
          allGroups.value = false;
        }
      }
    );

    watch(optionsGroups, (newGroup, oldGroup) => {
      if (allGroups.value && surveyTemplate.branches.length != 0) {
        if (newGroup.length > oldGroup.length) {
          optionsGroups.value.filter((group) => {
            if (!surveyTemplate.groups.includes(group.value)) {
              surveyTemplate.groups.push(group.value);
            }
          });
        }
      }
    });

    return {
      /* constants */
      breadcrumbs,
      surveyTemplate,
      filterListItens,
      listCategories,
      router,
      listBranches,
      allGroups,
      optionsGroups,
      /* Functions */
      onSubmit,
      moreOptions,
      deleteOption,
      filterFnGroups,
      filterFnBranches,
      selectedFilteredGroups,
    };
  },
};
</script>

<style scoped>
.q-btn--outline:before {
  border: 1px solid #ccc;
}

.container-form {
  height: 100%;
  max-height: 750px;
  overflow-y: scroll;
}

.container-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:is(.label-input, .label) {
  color: #666666;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.25px;
  margin-bottom: 4px;
}

.label-input::after {
  content: " *";
  color: #cc0000;
}

.container-inputs {
  display: flex;
  justify-content: space-between;
}

.container-inputs .item {
  width: calc(50% - 24px);
}

.more-options {
  width: 100%;
}

.container-groups {
  display: flex;
  flex-wrap: nowrap;
  align-items: baseline;
}

.input-groups {
  width: calc(90% - 25px);
  margin-right: 25px;
}
</style>
